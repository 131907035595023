import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, SliceZone, SEO, Header } from '../components';
import website from '../../config/website';

const Page = ({ data: { prismicPage, posts, press, agencies, jobs, realisations }, location }) => {
  const { data } = prismicPage;
  return (
    <Layout location={location}>
      <SEO
        title={`${data.main_title.text} | ${website.title}`}
        pathname={location.pathname}
        desc={data.introduction.text}
        node={prismicPage}
        // article
      />
      <Header light />
      <div>
        <SliceZone allSlices={data.body} posts={posts} press={press} agencies={agencies} jobs={jobs} realisations={realisations} location={location}/>
      </div>
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  data: PropTypes.shape({
    prismicPage: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
    realisations: PropTypes.object.isRequired,
    jobs: PropTypes.object.isRequired,
    press: PropTypes.object.isRequired,
    agencies: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PagebySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      first_publication_date
      last_publication_date
      data {
        main_title {
          html
          text
        }
        introduction {
          html
          text
        }
        body {
          ... on PrismicPageBodyHero {
            slice_type
            id
            primary {
              maintitle {
                html
                text
              }
              subtitle {
                html
                text
              }
              introduction {
                html
                text
              }
              bgimg {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1960, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              illustration {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              bgcolor
              orientation
              theme
            }
          }
            ... on PrismicPageBodyListeLiensOffres {
                id
                primary{
                    titre_du_bloc{
                        html
                        text
                    }
                    bgcolor
                }
                items {
                    offre {
                        document {
                            data {
                                thumb {
                                    min{
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 200, quality: 90) {
                                                    ...GatsbyImageSharpFluid
                                                }
                                            }
                                        }
                                    }
                                    full{
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 312, quality: 90) {
                                                    ...GatsbyImageSharpFluid
                                                }
                                            }
                                        }
                                    }
                                }
                                shortdesc {
                                    text
                                }
                                title {
                                    text
                                }
                                liste_description {
                                    html
                                    text
                                }
                            }
                            uid
                        }
                    }
                }
                slice_type
            }  
          ... on PrismicPageBodyGrid34 {
            slice_type
            id
            primary {
              title {
                text
                html
              }
                bgcolor
                columns
            }
            items {
              image {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 180, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              title {
                html
                text
              }
              text {
                text
                html
              }
              link {
                target
                link_type
                url
              }
              label
            }
          }
          ... on PrismicPageBodyListeDePartenaires {
            slice_type
            id
            primary {
              title {
                html
              }
              intro {
                html
              }
            }
            items {
              partners {
                uid
                document {
                  uid
                  data {
                    name
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 120, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      alt
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTexteEtImageEnDecale {
              id
              items {
                  image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 200, quality: 90) {
                                  ...GatsbyImageSharpFluid_withWebp
                              }
                          }
                      }
                      alt
                  }
                  texte {
                      html
                      text
                  }
              }
              slice_type
              primary {
                  bgcolor
                  title {
                      text
                      html
                  }
              }
          }
          ... on PrismicPageBodyChiffres {
              id
              primary {
                  title {
                      html
                      text
                  }
              }
              slice_type
              items {
                  image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 212, maxHeight: 230, quality: 90) {
                                  ...GatsbyImageSharpFluid_withWebp
                              }
                          }
                      }
                      alt
                  }
                  name
              }
          }
          ... on PrismicPageBodyArticlepage {
            slice_type
            id
            primary {
              direction
              title1 {
                html
                text
              }
              text {
                html
              }
              linklabel
              link {
                url
                target
                link_type
              }
              image {
                carre {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 482, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyBigbanner {
            slice_type
            id
            primary {
              bannertitle {
                text
                html
              }
              text {
                html
                text
              }
              label
              link {
                url
                target
                link_type
              }
              align
              theme
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1960, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyVideopage {
            slice_type
            id
            primary {
              title {
                html
              }
              texte {
                html
              }
              video {
                html
              }
              bgcolor
            }
          }
#          ... on PrismicPageBodyDocuments {
#            slice_type
#            id
#            primary {
#              title {
#                html
#                text
#              }
#              desc {
#                html
#                text
#              }
#            }
#            items {
#              selected_docs {
#                document {
#                  data {
#                    title
#                    desc {
#                      html
#                      text
#                    }
#                    image {
#                      url
#                      localFile {
#                        childImageSharp {
#                          fluid(maxWidth: 200, quality: 90) {
#                            ...GatsbyImageSharpFluid_withWebp
#                          }
#                        }
#                      }
#                    }
#                    links {
#                      link {
#                        url
#                        link_type
#                        target
#                      }
#                      label
#                      type
#                    }
#                  }
#                }
#              }
#            }
#          }
          ... on PrismicPageBodyContactpage {
            slice_type
            id
            primary {
              title {
                html
              }
              texte {
                html
              }
              bgcolor
            }
          }
          #          ... on PrismicPageBodyEquipe {
          #            slice_type
          #            id
          #            primary {
          #              title {
          #                html
          #                text
          #              }
          #              description {
          #                html
          #                text
          #              }
          #            }
          #          }
          ... on PrismicPageBodyTextpage {
            slice_type
            id
            primary {
                title1 {
                    html
                    text
                }
              text {
                html
              }
              bgcolor
              padding
            }
          }
          ... on PrismicPageBodyListeDePersonne {
            slice_type
            id
            primary {
              selectedtext {
                html
              }
              title{
                  html
                  text
              }
                bgcolor
            }
            items {
              person_selected {
                uid
                document {
                  uid
                  data {
                    image {
                      big {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 200, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                    bio {
                      html
                    }
                    testimony{
                        html
                    }
                    testimony1{
                        html
                    }
                      name
                  }
                }
              }
                selection_du_temoignage
            }
          }
          ... on PrismicPageBodyBlogListe {
            slice_type
            id
            primary {
              title {
                html
              }
              intro {
                html
              }
              more
            }
          }
          ... on PrismicPageBodyListeDesOffresDEmploi {
              id
              primary {
                  title {
                      html
                      text
                  }
                  bgcolor
              }
              slice_type
          }
          ... on PrismicPageBodyMiseEnAvantBlog {
            slice_type
            id
            primary {
              featured {
                url
                document {
                  uid
                  first_publication_date
                  last_publication_date
                  data {
                    title {
                      html
                      text
                    }
                    main {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 900, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    shortdesc {
                      html
                      text
                    }
                    categorie {
                      uid
                      document {
                        uid
                        data {
                          name
                          color
                        }
                      }
                    }
                    authors {
                      author {
                        document {
                          uid
                          data {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
              featuredtitle {
                html
              }
              featuredintro {
                html
              }
            }
          }
          ... on PrismicPageBodyListeImageTexte {
              id
              slice_type
              items {
                  image {
                      mini {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 200, quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
                  text {
                      html
                  }
                  title {
                      html
                  }
              }
          }
          ... on PrismicPageBodyAgencyListe {
              id
              slice_type
              primary {
                  intro {
                      html
                      text
                  }
                  title {
                      html
                      text
                  }
                  display
              }
          }
          ... on PrismicPageBodyReaListe {
              id
              primary {
                  more
                  bgcolor
                  title {
                      html
                      text
                  }
                  intro {
                      html
                      text
                  }
              }
              slice_type
          }
#          ... on PrismicPageBodyMiseEnAvantOffre {
#              id
#              slice_type
#              primary {
#                  offre {
#                      document {
#                          uid
#                          data {
#                              thumb {
#                                  localFile {
#                                      childImageSharp {
#                                          fluid(maxWidth: 300, quality: 90) {
#                                              ...GatsbyImageSharpFluid_withWebp
#                                          }
#                                      }
#                                  }
#                              }
#                              shortdesc {
#                                  html
#                                  text
#                              }
#                              title {
#                                  html
#                                  text
#                              }
#                          }
#                      }
#                  }
#              }
#          }
#          ... on PrismicPageBodyPresseListe {
#            slice_type
#            id
#            primary {
#              title {
#                text
#                html
#              }
#            }
#          }
#          ... on PrismicPageBodySubnavigation {
#            slice_type
#            id
#            primary {
#              subnav {
#                document {
#                  slugs
#                  id
#                  data {
#                    title {
#                      html
#                      text
#                    }
#                    subnav {
#                      link {
#                        url
#                        target
#                      }
#                      label
#                    }
#                  }
#                }
#              }
#            }
#          }
          #                    ... on PrismicPostBodyCodeBlock {
          #                        slice_type
          #                        id
          #                        primary {
          #                            code_block {
          #                                html
          #                            }
          #                        }
          #                    }
          ... on PrismicPageBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
              author
            }
          }
          ... on PrismicPageBodySecteursDActivite {
              id
              slice_type
              primary {
                  title {
                      html
                      text
                  }
                  bgcolor
              }
              items {
                  area {
                      document {
                          uid
                          data {
                              thumb {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 200, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                                  alt
                              }
                              secteur_d_activite {
                                  html
                                  text
                              }
                          }
                      }
                  }
              }
          }
          #                    ... on PrismicPostBodyVideo {
          #                        slice_type
          #                        id
          #                        primary {
          #                            video {
          #                                html
          #                            }
          #                        }
          #                    }
          #                    ... on PrismicPostBodyImage {
          #                        slice_type
          #                        id
          #                        primary {
          #                            image {
          #                                localFile {
          #                                    childImageSharp {
          #                                        fluid(maxWidth: 1200, quality: 90) {
          #                                            ...GatsbyImageSharpFluid_withWebp
          #                                        }
          #                                    }
          #                                }
          #                            }
          #                        }
          #                    }
        }
      }
    }
    #    persons: allPrismicPerson(
    #      sort: { fields: [data___order], order: ASC }
    #      filter: { data: { role: { document: { elemMatch: { uid: { eq: "equipe" } } } } } }
    #    ) {
    #      edges {
    #        node {
    #          data {
    #            image {
    #              big {
    #                localFile {
    #                  childImageSharp {
    #                    fluid(maxWidth: 200, quality: 90) {
    #                      ...GatsbyImageSharpFluid_withWebp
    #                    }
    #                  }
    #                }
    #              }
    #            }
    #            bio {
    #              html
    #              text
    #            }
    #            name
    #            order
    #            role {
    #              document {
    #                data {
    #                  role
    #                }
    #              }
    #            }
    #          }
    #        }
    #      }
    #    }
#    press: allPrismicPresse {
#      edges {
#        node {
#          uid
#          data {
#            title {
#              html
#              text
#            }
#            shortdesc {
#              text
#            }
#            main {
#              url
#              full {
#                url
#              }
#            }
#            date(formatString: "DD.MM.YYYY")
#            body {
#              ... on PrismicPresseBodyText {
#                slice_type
#                id
#                primary {
#                  text {
#                    html
#                  }
#                }
#              }
#            }
#          }
#        }
#      }
#    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    realisations: allPrismicPost(filter: {data: {categorie: {uid: {eq: "realisations"}}}}, limit: 3, sort: { fields: [data___date], order: DESC }) {
        edges {
            node {
                uid
                first_publication_date
                last_publication_date
                data {
                    title {
                        text
                    }
                    shortdesc {
                        text
                    }
                    main {
                        full {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400, quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    date(formatString: "DD.MM.YYYY")
                    categorie {
                        document {
                            uid
                            data {
                                name
                                color
                            }
                        }
                    }
                    authors {
                        author {
                            id
                            uid
                            document {
                                data {
                                    name
                                    image {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    agencies: allPrismicAgency (sort: { fields: [first_publication_date], order: ASC }){
        edges {
            node {
                uid
                first_publication_date
                data {
                    name{
                      text
                      html
                    }
                    adresse_1
                    cp
                    image {
                        alt
                        thumb {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 300, quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    
                    ville
                    phone
                }
            }
        }
    }
    jobs: allPrismicEmploi (filter: { uid: { ne: "dummy" } }, sort: { fields: [last_publication_date], order: DESC }) {
          edges {
              node {
                  uid
                  last_publication_date
                  data {
                      date
                      localisation
                      remuneration
                      title {
                          html
                          text
                      }
                      image {
                          thumb {
                              alt
                          }
                      }
                      type
                      shortdesc {
                          html
                          text
                      }
                  }
              }
          }
      }
  }
`;
